import { LibraryTag } from '@/models/LibraryTag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Article } from '@/models/Article';
import { debounce } from 'lodash';
import MiFileUpload from '@/components/mi-file-upload/MiFileUpload';

@Component<ReleaseArticleDialog>({
  components: {
    QuillEditor,
  },
})
export default class ReleaseArticleDialog extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  @Prop({ default: '' })
  protected folderId!: string;
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */

  protected isLoading = false;

  protected newArticle: NewArticle = {
    title: '',
    tags: [],
    content: '',
    media: [],
  };

  protected tags: LibraryTag[] = [];

  protected tagSearch = '';

  protected debouncedSearchTags: Function = debounce(this.fetchTags, 300);
  // #endregion

  // #region Lifecycle Hooks / Init

  mounted() {
    this.fetchTags();
  }
  // #endregion

  // #region Class methods

  protected close() {
    this.$emit('close');
  }

  protected handleItemDropped(file: File) {
    if (! file) {
      return;
    }

    this.newArticle.media.push(file);
    const fileUploadComponent = this.$refs.fileUpload as MiFileUpload;
    fileUploadComponent.uploaded();
  }

  protected removeTag(tag: LibraryTag) {
    if (! this.newArticle.tags) {
      return;
    }
    const index = this.newArticle.tags.indexOf(tag);
    this.newArticle.tags.splice(index, 1);
  }

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  protected async fetchTags(): Promise<void> {
    this.tags = await new LibraryTag()
      .filter({ search: this.tagSearch })
      .all();
  }

  protected async createTag() {
    const tag = await new LibraryTag()
      .create({ title: this.tagSearch })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (tag) {
      this.tags.push(tag);
      this.newArticle.tags.push(tag);
    }
  }

  protected async createArticle(): Promise<void> {
    this.isLoading = true;
    const createdArticle = await new Article()
      .create({
        title: this.newArticle.title,
        folder: this.folderId || null,
        tags: this.newArticle.tags?.map((tag) => tag.id) || [],
        content: this.newArticle.content,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (! createdArticle || ! this.newArticle.media) {
      return;
    }

    const filePromises = this.newArticle.media.map((file) => this.addFileToArticle(file, createdArticle));
    const result = await Promise.all(filePromises);

    if (result) {
      this.$emit('created');
      this.close();
      this.isLoading = false;
    }
  }

  protected async addFileToArticle(file: File, createdArticle: Article): Promise<unknown> {
    return await await createdArticle
      .mediaEndpoint()
      .create({
        file,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });
  }
  // #endregion

  // #region Getters & Setters

  protected get canSubmit() {
    return !! this.newArticle.title && !! this.newArticle.tags?.length && !! this.newArticle.content;
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

interface NewArticle {
  title: string;
  tags: LibraryTag[];
  content: string;
  media: File[];
}
