import { DateTime } from 'luxon';
import DataTable, { TableMeta, TableVisibility } from '@/components/data-table/DataTable';
import { Media } from '@/models/Report';
import { Article } from '@/models/Article';
import { sanitizeString } from '@/support/String';
import { Component, Vue } from 'vue-property-decorator';
import ReleaseArticleDialog from '@/views/ReleaseDashboard/ReleaseArticleDialog/ReleaseArticleDialog.vue';
import LibraryTree from '@/components/LibraryTree/LibraryTree.vue';
import { LibraryItem } from '@/components/LibraryTree/LibraryTree';
import { Folder } from '@/models/Folder';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import LibraryDialog from '@/components/dialog/library-dialog/LibraryDialog.vue';
import { userRoles, User } from '@/models/User';
import { Options } from '@/components/mi-dialog/MiDialog';
import { LibraryTag } from '@/models/LibraryTag';
import { cloneDeep, debounce } from 'lodash';
import MediaDialog from '@/components/dialog/media-dialog/MediaDialog.vue';
import QuillEditor from '@/components/quill-editor/QuillEditor.vue';
import { Rpc } from '@/models/Rpc';
import SortableFolderTree from '@/components/sortable-folder-tree/SortableFolderTree.vue';
import { SortItem } from '@/components/sortable-folder-tree/SortableFolderTree';

@Component<ReleaseDashboard>({
  components: {
    ReleaseArticleDialog,
    LibraryTree,
    LibraryDialog,
    MediaDialog,
    QuillEditor,
    SortableFolderTree,
  },
})
export default class ReleaseDashboard extends Vue {
  // #region @Props
  /**
   * ie.
   * @Prop()
   * protected user!: User
   */
  // #endregion

  // #region @Refs
  /**
   * ie.
   * @Ref()
   * readonly anotherComponent!: AnotherComponent
   */
  // #endregion

  // #region Class properties
  /**
   * ie.
   * protected isLoading = true;
   * * protected company: Company | null = null;
   */
  protected $pageTitle = 'Kennisbank';

  protected folders: LibraryItem[] = [];

  protected allArticles: Article[] = [];

  protected tags: LibraryTag[] = [];

  protected draggableFolders: LibraryItem[] | null = [];

  protected selectedReleaseItem: Article | null = null;

  protected selectedReleaseClone: Article | null = null;

  protected selectedLibraryItem: LibraryItem | null = null;

  protected releaseDialogType = '';

  protected isDisplayingReleaseItemDialog = false;

  protected newArticle: NewArticle = {
    title: '',
    tags: [],
    content: '',
    media: [],
  };

  protected libraryDialogType = '';

  protected isDisplayingLibraryItemDialog = false;

  protected isCreatingRelease = false;

  protected isLoading = true;

  protected isEditing = false;

  protected tagSearch = '';

  protected debouncedSearchTags: Function = debounce(this.fetchTags, 300);

  protected inputSearch: string | null = null;

  protected imageModel: Article | null = null;

  protected isDisplayingCreateImageDialog = false;

  protected isEditingSettings = false;

  protected selectedImage: Media | null = null;

  protected libraryItemPayload = {
    title: '',
  };

  protected articleFilters: ArticleFilter = {
    type: 'changelist',
    status: (this.isAdmin || this.isHelpdesk) && this.$store.state.isServiceOrganization ? '' : 'released',
  };

  protected total = 0;

  protected lastPage = 1;

  protected from = 0;

  protected to = 0;

  protected page = 1;

  protected limit = 15;

  public paginationDebounce: Function = this.handlePagination();

  protected quilEditorOptions = {
    modules: {
      toolbar: [
        [{ header: 4 }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        ['image', 'link'],
      ],
    },
    placeholder: 'Type hier...',
  };

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected selectedTag: LibraryTag | null = null;
  // #endregion

  // #region Lifecycle Hooks / Init

  protected mounted(): void {
    this.initialize();
  }

  protected async initialize(): Promise<void> {
    this.initializeBreadcrumb();
    this.fetchFolders();
    this.fetchTags();
    this.fetchArticles();
  }
  // #endregion

  // #region Class methods

  protected initializeBreadcrumb(): void {
    this.$root.$emit('breadcrumbUpdated', {
      crumb: [{ name: 'Kennisbank' }],
    });
  }

  protected sanitizeString(value: string): string {
    return sanitizeString(value, true);
  }

  protected onClickCreateImageDialog(): void {
    this.imageModel = cloneDeep((this.selectedReleaseItem as Article).mediaEndpoint()) || null;
    this.isDisplayingCreateImageDialog = true;
  }

  protected onClickDeleteImage(image: Media): void {
    if (image) {
      this.selectedImage = image;
      this.$store.dispatch('openDialog', this.dialogOptionsDeleteImage);
    }
  }

  protected onClickSelected(article: Article): void {
    if (this.isEditing) {
      this.cancelIsEditing();
    }

    this.selectedReleaseItem = article;
    this.fetchArticle(this.selectedReleaseItem.id || '');
  }

  protected onClickFolder(item: LibraryItem): void {
    this.openLibraryItemDialog('folder', item);
  }

  protected onClickArticle(item: LibraryItem): void {
    this.openLibraryItemDialog('article', item);
  }

  protected onClickEdit(item: LibraryItem): void {
    this.openLibraryItemDialog('edit', item);
  }

  protected onClickDelete(item: LibraryItem): void {
    if (item) {
      this.selectedLibraryItem = item;
      this.$store.dispatch('openDialog', this.dialogOptionsDelete);
    }
  }

  protected openLibraryItemDialog(type: string, item: LibraryItem): void {
    this.selectedLibraryItem = item || null;
    this.libraryDialogType = type;
    this.isDisplayingLibraryItemDialog = true;
  }

  protected resetLibraryItem(): void {
    this.libraryDialogType = '';
    this.libraryItemPayload.title = '';
    this.selectedLibraryItem = null;
    this.isDisplayingLibraryItemDialog = false;
  }

  protected async resetSelectedArticle(): Promise<void> {
    await this.cancelIsEditing();
    this.selectedReleaseItem = null;
  }

  protected isEditingArticle(article?: Article) {
    if (article) {
      this.selectedReleaseItem = article;
    }

    this.selectedReleaseClone = cloneDeep(this.selectedReleaseItem);
    this.isEditing = true;
  }

  protected async cancelIsEditing() {
    await this.fetchArticles();
    if (this.selectedReleaseClone && this.selectedReleaseClone) {
      (this.selectedReleaseClone as Article).media = (this.selectedReleaseClone as Article).media;
    }
    this.selectedReleaseItem = this.selectedReleaseClone;
    this.isEditing = false;
  }

  protected getStatusClass(status: string): string {
    if (status === 'released') {
      return 'release-tag--released';
    }
    if (status === 'draft') {
      return 'release-tag--draft';
    }

    return '';
  }

  protected getStatusName(status: string): string {
    if (status === 'released') {
      return 'Openbaar';
    }
    if (status === 'draft') {
      return 'Concept';
    }

    return '';
  }

  protected isArticleReleased(status: string): boolean {
    if (status === 'released') {
      return true;
    }
    return false;
  }

  protected async createdLibraryItem(item: LibraryItem) {
    await this.fetchFolders();

    if (item.internal_type === 'folders') {
      return;
    }

    this.selectedReleaseItem = item as Article;
    this.isEditing = true;
  }

  protected onClickEditSettings() {
    this.draggableFolders = cloneDeep(this.folders);
    this.isEditingSettings = true;
  }

  protected onClickCancelEditSettings() {
    this.draggableFolders = null;
    this.isEditingSettings = false;
  }

  public handlePagination() {
    return debounce(
      (page: number) => {
        this.page = page;
        this.fetchArticles();
      },
      300,
    );
  }

  protected refreshDataTable() {
    document.dispatchEvent(
      new CustomEvent('datatable:hook', {
        detail: (dataTable: DataTable) => {
          dataTable.refresh();
        },
      }),
    );
  }
  // #endregion

  // #region Async methods
  /**
   * ie.
   * protected async fetchUserCompany(): Promise<void> {
   *  this.company = await new Company().filter({user: this.user.id}).all();
   * }
   */
  protected async fetchFolders(): Promise<void> {
    const folders = await new Folder()
      .filter(this.articleFilters)
      .include(['folders', 'articles', 'tags', 'media'])
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (! folders) {
      return;
    }

    folders.forEach((folder: Folder) => {
      folder.createChildren(folder);
    });

    this.folders = folders;
  }

  protected async fetchArticle(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    this.isLoading = true;

    const selectedArticle = await new Article()
      .filter({ type: 'changelist' })
      .include(['tags', 'media', 'user'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (selectedArticle) {
      this.selectedReleaseItem = selectedArticle;
      this.fetchFolders();
      this.isLoading = false;
    }
  }

  protected async fetchArticleMedia(articleId: string): Promise<void> {
    if (! articleId) {
      return;
    }

    const selectedReleaseItem = await new Article()
      .include(['media'])
      .find(articleId)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (selectedReleaseItem) {
      (this.selectedReleaseItem as Article).media = selectedReleaseItem.media;
      this.fetchFolders();
    }
  }

  protected async fetchArticles(): Promise<void> {
    this.isLoading = true;

    this.allArticles = await new Article()
      .filter(this.articleFilters)
      .include(['tags', 'media', 'user'])
      .page(this.page)
      .limit(this.limit)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });

    this.total = this.allArticles[0] !== undefined ? (this.allArticles[0].meta as TableMeta).total : 0;
    this.lastPage = this.allArticles[0] !== undefined ? (this.allArticles[0].meta as TableMeta).last_page : 1;
    this.from = this.allArticles[0] !== undefined ? (this.allArticles[0].meta as TableMeta).from : 0;
    this.to = this.allArticles[0] !== undefined ? (this.allArticles[0].meta as TableMeta).to : 0;

    this.isLoading = false;
  }

  protected async fetchTags(): Promise<void> {
    this.tags = await new LibraryTag().filter({ search: this.tagSearch }).all().catch((error: AxiosError) => {
      ErrorHandler.network(error);
      return [];
    });
  }

  protected async createTag() {
    const tag = await new LibraryTag()
      .create({ title: this.tagSearch })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (tag) {
      this.tags.push(tag);
      this.newArticle.tags.push(tag);
      this.refreshDataTable();
      this.tagSearch = '';
      this.fetchTags();
    }
  }

  protected async saveArticle(): Promise<void> {
    if (! this.selectedReleaseItem) {
      return;
    }

    this.isLoading = true;

    const selectedRelease = (this.selectedReleaseItem as Article);

    const article = await new Article(selectedRelease)
      .update({
        title: selectedRelease.title || '',
        tags: selectedRelease.tags?.map((tag: LibraryTag) => tag.id) || [],
        content: selectedRelease.content || '',
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (article) {
      this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
      this.isEditing = false;
    }

    this.isLoading = false;
  }

  protected async releaseArticle(): Promise<void> {
    if (! this.selectedReleaseItem) {
      return;
    }

    this.isLoading = true;

    const selectedRelease = (this.selectedReleaseItem as Article);

    const user = await new User().current().then((user: User) => user);

    const article = await new Article(selectedRelease)
      .update({
        status: 'released',
        released_at: DateTime.local(),
        user: user.uuid,
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (article) {
      this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
      this.isEditing = false;
      this.selectedReleaseItem = null;
      this.fetchArticles();
    }
    this.fetchFolders();
    this.isLoading = false;
  }

  protected async setArticleDraft(): Promise<void> {
    if (! this.selectedReleaseItem) {
      return;
    }

    this.isLoading = true;

    const selectedRelease = (this.selectedReleaseItem as Article);

    const article = await new Article(selectedRelease)
      .update({
        status: 'draft',
        released_at: '',
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return null;
      });

    if (article) {
      this.$store.dispatch('openDialog', this.dialogOptionsReportConfirm);
      this.isEditing = false;
      this.selectedReleaseItem = null;
      this.fetchArticles();
    }
    this.isLoading = false;
  }

  protected async itemChanged(sortItem: SortItem) {
    const item = sortItem.item.moved ? sortItem.item.moved.element : sortItem?.item?.added?.element || null;
    const data = sortItem.list.map((libraryItem: LibraryItem) => ({
      type: libraryItem.internal_type,
      item: libraryItem.id,
    }));

    if (! sortItem?.parent?.id) {
      this.draggableFolders = cloneDeep(this.folders);
      return;
    }

    if (item) {
      const payload = {
        signature: 'library:folder-attach-children',
        body: {
          parent: sortItem.parent.id,
          data,
        },
      };

      await new Rpc()
        .rpcPost(payload)
        .catch((error: AxiosError) => {
          ErrorHandler.network(error);
        });
    }

    await this.fetchFolders();
    this.draggableFolders = cloneDeep(this.folders);
  }
  // #endregion

  // #region Getters & Setters

  protected get dialogOptionsReportConfirm(): Options {
    return {
      title: 'Gelukt!',
      text: 'Het opslaan is gelukt.',
      type: 'success',
      buttons: {
        confirm: {
          text: 'ok',
          action: () => {
            this.$emit('reload');
          },
        },
      },
    };
  }

  protected get dialogOptionsDeleteImage(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je ${this.selectedImage?.file_name || 'deze item'} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.selectedImage || ! this.selectedImage.uuid || ! this.selectedReleaseItem) {
              return;
            }

            await (this.selectedReleaseItem as Article).deleteMediaRequest(this.selectedImage.uuid)
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
                return null;
              });

            this.fetchArticle(this.selectedReleaseItem.id || '');
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedImage = null;
          },
        },
      },
    };
  }

  protected get dialogOptionsDelete(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je ${this.selectedLibraryItem?.title || 'deze item'} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.selectedLibraryItem) {
              return;
            }

            await this.selectedLibraryItem.delete().catch((error: AxiosError) => {
              ErrorHandler.network(error);
              return null;
            });

            this.fetchFolders();
            this.fetchArticles();
            this.selectedLibraryItem = null;
            this.selectedReleaseItem = null;
            this.isEditing = false;
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedLibraryItem = null;
          },
        },
      },
    };
  }

  protected get conceptPill(): string {
    return '<span class="navigation-concept-pill">C</span>';
  }

  protected get isAdmin(): boolean {
    return !! this.$store.state.Auth.hasRole(userRoles.AdminRoles);
  }

  protected get isHelpdesk(): boolean {
    return !! this.$store.state.Auth.hasRole(userRoles.HelpdeskTCMG);
  }

  protected get tableOptionsTags(): Object {
    return {
      model: new LibraryTag(),
      headers: [
        {
          text: 'Naam',
          value: 'title',
        },
      ],
      actions: [
        {
          type: 'delete',
          label: 'Verwijderen',
          icon: 'delete',
          tooltip: 'Verwijderen',
          action: (datatable: DataTable, tag: LibraryTag) => {
            this.selectedTag = tag;
            this.$store.dispatch('openDialog', this.dialogOptionsDeleteTag);
          },
        },
      ],
    };
  }

  protected get dialogOptionsDeleteTag(): Options {
    return {
      title: this.$t('dialogOptions.confirmation').toString(),
      text: `Weet je zeker dat je ${this.selectedTag?.title || 'deze item'} wilt verwijderen?`,
      type: 'warning',
      buttons: {
        confirm: {
          text: this.$t('dialogOptions.button.delete').toString(),
          color: 'warning',
          action: async () => {
            if (! this.selectedTag || ! this.selectedTag.id) {
              return;
            }

            await new LibraryTag(this.selectedTag)
              .delete()
              .catch((error: AxiosError) => {
                ErrorHandler.network(error);
                return null;
              });

            this.selectedTag = null;
            this.fetchTags();
            this.refreshDataTable();
          },
        },
        cancel: {
          text: this.$t('dialogOptions.button.cancel').toString(),
          color: 'text-light',
          action: () => {
            this.selectedTag = null;
          },
        },
      },
    };
  }
  // #endregion

  // #region @Watchers

  // #endregion
}

// #region Interface & Types
/**
 * ie.
 * interface Media
 * Type Media
 */
interface NewArticle {
  title: string;
  tags: LibraryTag[];
  content: string;
  media: File[];
}

interface ArticleFilter {
  tags?: string[];
  status?: string;
  type?: string;
}
// #endregion
