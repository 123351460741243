var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { fluid: "", "grid-list-lg": "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
                _c("h2", { staticClass: "elementSubTitle" }, [
                  _vm._v("Kennisbank")
                ])
              ]),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm4: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _vm.isEditingSettings
                            ? [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "elementPanel elementPanel--spaced"
                                  },
                                  [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      {
                                                        staticClass: "link",
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.onClickCancelEditSettings()
                                                          }
                                                        }
                                                      },
                                                      on
                                                    ),
                                                    [_vm._v("west")]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3399949636
                                        )
                                      },
                                      [_c("span", [_vm._v("Bekijk de lijst")])]
                                    ),
                                    _c(
                                      "h2",
                                      { staticClass: "elementSubTitle" },
                                      [_vm._v("Categorieën beheren")]
                                    ),
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "pb-3 ma-0",
                                        attrs: {
                                          "justify-space-between": "",
                                          wrap: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "" } },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  "justify-space-between": "",
                                                  "align-baseline": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs10: "" } },
                                                  [
                                                    _c("v-text-field", {
                                                      attrs: {
                                                        label:
                                                          "Categorie aanmaken"
                                                      },
                                                      model: {
                                                        value: _vm.tagSearch,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.tagSearch = $$v
                                                        },
                                                        expression: "tagSearch"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-flex",
                                                  { attrs: { xs2: "" } },
                                                  [
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "link",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.createTag()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        "add"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          330188598
                                                        )
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "Categorie toevoegen"
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-flex",
                                          { attrs: { xs12: "", "px-0": "" } },
                                          [
                                            _c("DataTable", {
                                              ref: "tagsDatatable",
                                              attrs: {
                                                options: _vm.tableOptionsTags,
                                                limit: 10,
                                                visibility: _vm.visibility
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "elementPanel" },
                                  [
                                    _c(
                                      "h2",
                                      { staticClass: "elementSubTitle" },
                                      [_vm._v("Pas volgorde aan")]
                                    ),
                                    _c(
                                      "v-layout",
                                      {
                                        staticClass: "pb-3 ma-0",
                                        attrs: { "justify-space-between": "" }
                                      },
                                      [
                                        _c("SortableFolderTree", {
                                          on: { itemChanged: _vm.itemChanged },
                                          model: {
                                            value: _vm.draggableFolders,
                                            callback: function($$v) {
                                              _vm.draggableFolders = $$v
                                            },
                                            expression: "draggableFolders"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            : _vm._e(),
                          !_vm.isEditingSettings
                            ? _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  (_vm.isAdmin || _vm.isHelpdesk) &&
                                  _vm.$store.state.isServiceOrganization
                                    ? [
                                        _c(
                                          "v-layout",
                                          {
                                            staticClass: "pb-3 ma-0",
                                            attrs: {
                                              "justify-space-between": ""
                                            }
                                          },
                                          [
                                            !_vm.isEditingSettings
                                              ? _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              _vm.folders.length
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "link",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.onClickEditSettings()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " settings "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      764020410
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Instellingen")
                                                    ])
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "MiButton",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  color: "primary",
                                                  icon: "create_new_folder",
                                                  compact: true,
                                                  outline: true
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.openLibraryItemDialog(
                                                      "folder"
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Nieuwe folder ")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e(),
                                  _c("v-autocomplete", {
                                    staticClass: "mt-3",
                                    attrs: {
                                      items: _vm.tags,
                                      "search-input": _vm.tagSearch,
                                      "item-text": "title",
                                      "item-value": "id",
                                      multiple: "",
                                      label: "Filter op categorie",
                                      clearable: "",
                                      "prepend-icon": "mdi-tag-multiple",
                                      "no-data-text":
                                        "Geen categorieën gevonden"
                                    },
                                    on: {
                                      keyup: _vm.debouncedSearchTags,
                                      "update:searchInput": function($event) {
                                        _vm.tagSearch = $event
                                      },
                                      "update:search-input": function($event) {
                                        _vm.tagSearch = $event
                                      },
                                      change: function($event) {
                                        return _vm.fetchArticles()
                                      }
                                    },
                                    model: {
                                      value: _vm.articleFilters.tags,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.articleFilters,
                                          "tags",
                                          $$v
                                        )
                                      },
                                      expression: "articleFilters.tags"
                                    }
                                  }),
                                  _c("v-text-field", {
                                    staticClass: "search-bar",
                                    attrs: {
                                      label: "Zoeken",
                                      clearable: "",
                                      "clear-icon": "close",
                                      "prepend-icon": "search"
                                    },
                                    model: {
                                      value: _vm.inputSearch,
                                      callback: function($$v) {
                                        _vm.inputSearch = $$v
                                      },
                                      expression: "inputSearch"
                                    }
                                  }),
                                  _vm.folders.length
                                    ? _c(
                                        "v-layout",
                                        { attrs: { wrap: "", "pl-3": "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              staticClass: "library-home",
                                              attrs: {
                                                shrink: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.resetSelectedArticle()
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [_vm._v("home")]
                                              ),
                                              _c("span", [
                                                _vm._v("Toon alle artikelen")
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("LibraryTree", {
                                    attrs: {
                                      items: _vm.folders,
                                      editable:
                                        (_vm.isAdmin || _vm.isHelpdesk) &&
                                        _vm.$store.state.isServiceOrganization,
                                      search: _vm.inputSearch,
                                      active: _vm.selectedReleaseItem
                                    },
                                    on: {
                                      onClickSelected: _vm.onClickSelected,
                                      onClickFolder: _vm.onClickFolder,
                                      onClickArticle: _vm.onClickArticle,
                                      onClickEdit: _vm.onClickEdit,
                                      onClickDelete: _vm.onClickDelete
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "label",
                                          fn: function(ref) {
                                            var item = ref.item
                                            return [
                                              item.status === "draft"
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "concept-marker"
                                                    },
                                                    [
                                                      _c("strong", [
                                                        _vm._v("C")
                                                      ])
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    item ? item.title : ""
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2453454043
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "" } },
                [
                  _vm.isLoading
                    ? _c("LoaderCard", { attrs: { type: "spinner--center" } })
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        !_vm.isEditing
                          ? [
                              !_vm.selectedReleaseItem
                                ? [
                                    _vm._l(_vm.allArticles, function(article) {
                                      return _c(
                                        "div",
                                        {
                                          key: "release" + article.id,
                                          staticClass: "release elementPanel"
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            {
                                              attrs: {
                                                "align-baseline": "",
                                                "justify-between": ""
                                              }
                                            },
                                            [
                                              _c("v-flex", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "release-tag",
                                                    class: _vm.getStatusClass(
                                                      article.status
                                                    )
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.getStatusName(
                                                            article.status
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              ]),
                                              _c(
                                                "v-flex",
                                                { attrs: { shrink: "" } },
                                                [
                                                  _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function(ref) {
                                                              var on = ref.on
                                                              return [
                                                                (_vm.isAdmin ||
                                                                  _vm.isHelpdesk) &&
                                                                _vm.$store.state
                                                                  .isServiceOrganization
                                                                  ? _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          staticClass:
                                                                            "link",
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.isEditingArticle(
                                                                                article
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " edit "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Aanpassen")
                                                      ])
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "release-title" },
                                            [
                                              _c(
                                                "h2",
                                                { staticClass: "mb-1" },
                                                [_vm._v(_vm._s(article.title))]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "date-container" },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "release-icon" },
                                                [_vm._v("mdi-calendar-edit")]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm._f("dateFormatTime")(
                                                      article.released_at
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              article && article.user
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          article.user.name
                                                            ? "door " +
                                                                article.user
                                                                  .name
                                                            : ""
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "tag-container" },
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "release-icon" },
                                                [_vm._v("mdi-tag-multiple")]
                                              ),
                                              _vm._l(article.tags, function(
                                                tag,
                                                index
                                              ) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: "tag" + index,
                                                    staticClass: "release-tag"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(tag.title) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _c("div", [
                                            _c("div", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.sanitizeString(
                                                    article.content
                                                  )
                                                )
                                              }
                                            })
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "library-content" },
                                            [
                                              _vm._l(article.media, function(
                                                media,
                                                index
                                              ) {
                                                return [
                                                  _c("MediaViewer", {
                                                    key: "images-" + index,
                                                    attrs: { media: media }
                                                  })
                                                ]
                                              })
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    _vm.allArticles.length
                                      ? _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "align-center": "",
                                              "justify-center": "",
                                              column: ""
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              {},
                                              [
                                                _c("v-pagination", {
                                                  attrs: {
                                                    length: _vm.lastPage,
                                                    "total-visible": 7
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.paginationDebounce
                                                  },
                                                  model: {
                                                    value: _vm.page,
                                                    callback: function($$v) {
                                                      _vm.page = $$v
                                                    },
                                                    expression: "page"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              { staticClass: "search-total" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(_vm.from) +
                                                      " - " +
                                                      _vm._s(_vm.to) +
                                                      " van " +
                                                      _vm._s(_vm.total)
                                                  )
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                : [
                                    _c(
                                      "div",
                                      { staticClass: "elementPanel" },
                                      [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "align-baseline": "",
                                              "justify-between": ""
                                            }
                                          },
                                          [
                                            _c("v-flex", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "release-tag",
                                                  class: _vm.getStatusClass(
                                                    _vm.selectedReleaseItem
                                                      .status
                                                  )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusName(
                                                          _vm
                                                            .selectedReleaseItem
                                                            .status
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "v-flex",
                                              { attrs: { shrink: "" } },
                                              [
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { bottom: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function(ref) {
                                                            var on = ref.on
                                                            return [
                                                              (_vm.isAdmin ||
                                                                _vm.isHelpdesk) &&
                                                              _vm.$store.state
                                                                .isServiceOrganization
                                                                ? _c(
                                                                    "v-icon",
                                                                    _vm._g(
                                                                      {
                                                                        staticClass:
                                                                          "link",
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.isEditingArticle()
                                                                          }
                                                                        }
                                                                      },
                                                                      on
                                                                    ),
                                                                    [
                                                                      _vm._v(
                                                                        " create "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1752395694
                                                    )
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Aanpassen")
                                                    ])
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "release-title" },
                                          [
                                            _c("h2", { staticClass: "mb-1" }, [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedReleaseItem.title
                                                )
                                              )
                                            ])
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "date-container" },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "release-icon" },
                                              [_vm._v("mdi-calendar-edit")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm._f("dateFormatTime")(
                                                    _vm.selectedReleaseItem
                                                      .released_at
                                                  )
                                                ) +
                                                " "
                                            ),
                                            _vm.selectedReleaseItem &&
                                            _vm.selectedReleaseItem.user
                                              ? _c("span", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedReleaseItem
                                                          .user.name
                                                          ? "door " +
                                                              _vm
                                                                .selectedReleaseItem
                                                                .user.name
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "tag-container" },
                                          [
                                            _c(
                                              "v-icon",
                                              { staticClass: "release-icon" },
                                              [_vm._v("mdi-tag-multiple")]
                                            ),
                                            _vm._l(
                                              _vm.selectedReleaseItem.tags,
                                              function(tag, index) {
                                                return _c(
                                                  "span",
                                                  {
                                                    key: "tag" + index,
                                                    staticClass: "release-tag"
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(tag.title) +
                                                        " "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _c("div", [
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.sanitizeString(
                                                  _vm.selectedReleaseItem
                                                    .content
                                                )
                                              )
                                            }
                                          })
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "library-content" },
                                          [
                                            _vm._l(
                                              _vm.selectedReleaseItem.media,
                                              function(media, index) {
                                                return [
                                                  _c("MediaViewer", {
                                                    key: "images-" + index,
                                                    attrs: { media: media }
                                                  })
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      ],
                                      1
                                    )
                                  ]
                            ]
                          : _vm._e(),
                        _vm.isEditing
                          ? [
                              _c(
                                "div",
                                { staticClass: "elementPanel" },
                                [
                                  _vm.selectedReleaseItem
                                    ? [
                                        _c(
                                          "v-layout",
                                          {
                                            attrs: {
                                              "mb-2": "",
                                              "justify-between": ""
                                            }
                                          },
                                          [
                                            _c("v-flex", [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "release-tag",
                                                  class: _vm.getStatusClass(
                                                    _vm.selectedReleaseItem
                                                      .status
                                                  )
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.getStatusName(
                                                          _vm
                                                            .selectedReleaseItem
                                                            .status
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  xs12: "",
                                                  "text-xs-right": "",
                                                  "pa-0": ""
                                                }
                                              },
                                              [
                                                !_vm.isArticleReleased(
                                                  _vm.selectedReleaseItem.status
                                                )
                                                  ? _c(
                                                      "MiButton",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          color: "success",
                                                          icon: "send",
                                                          compact: true,
                                                          outline: true
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.releaseArticle()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Openbaar maken "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm.isArticleReleased(
                                                  _vm.selectedReleaseItem.status
                                                )
                                                  ? _c(
                                                      "MiButton",
                                                      {
                                                        staticClass: "ml-1",
                                                        attrs: {
                                                          color: "orange",
                                                          icon:
                                                            "keyboard_return",
                                                          compact: true,
                                                          outline: true
                                                        },
                                                        nativeOn: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.setArticleDraft()
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Instellen als concept "
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "library-content" },
                                          [
                                            _c(
                                              "h2",
                                              { staticClass: "library-title" },
                                              [_vm._v("Titel")]
                                            ),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: "Naam",
                                                "prepend-icon":
                                                  "mdi-format-title"
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedReleaseItem.title,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedReleaseItem,
                                                    "title",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedReleaseItem.title"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "library-content" },
                                          [
                                            _c(
                                              "h2",
                                              { staticClass: "library-title" },
                                              [_vm._v("Categorie")]
                                            ),
                                            _c("v-autocomplete", {
                                              staticClass: "mt-3",
                                              attrs: {
                                                items: _vm.tags,
                                                "search-input": _vm.tagSearch,
                                                "item-text": "title",
                                                "return-object": "",
                                                multiple: "",
                                                label: "Kies een categorie",
                                                clearable: "",
                                                "prepend-icon":
                                                  "mdi-tag-multiple"
                                              },
                                              on: {
                                                keyup: _vm.debouncedSearchTags,
                                                "update:searchInput": function(
                                                  $event
                                                ) {
                                                  _vm.tagSearch = $event
                                                },
                                                "update:search-input": function(
                                                  $event
                                                ) {
                                                  _vm.tagSearch = $event
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "no-data",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "no-contact-results",
                                                            on: {
                                                              click:
                                                                _vm.createTag
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-1"
                                                              },
                                                              [_vm._v("add")]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                "Maak een nieuwe categorie aan"
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ],
                                                null,
                                                false,
                                                2016314980
                                              ),
                                              model: {
                                                value:
                                                  _vm.selectedReleaseItem.tags,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedReleaseItem,
                                                    "tags",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedReleaseItem.tags"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "library-content" },
                                          [
                                            _c(
                                              "h2",
                                              { staticClass: "library-title" },
                                              [_vm._v("Artikel omschrijving:")]
                                            ),
                                            _c("QuillEditor", {
                                              attrs: {
                                                options: _vm.quilEditorOptions
                                              },
                                              model: {
                                                value:
                                                  _vm.selectedReleaseItem
                                                    .content,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.selectedReleaseItem,
                                                    "content",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedReleaseItem.content"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "library-content" },
                                          [
                                            _c(
                                              "v-layout",
                                              {
                                                attrs: {
                                                  "justify-space-between": "",
                                                  "align-center": "",
                                                  "pa-0": "",
                                                  "ma-0": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "h2",
                                                  {
                                                    staticClass: "library-title"
                                                  },
                                                  [_vm._v("Voorbeelden:")]
                                                ),
                                                _c(
                                                  "MiButton",
                                                  {
                                                    attrs: {
                                                      icon: "add",
                                                      compact: true,
                                                      color: "secondary"
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        return _vm.onClickCreateImageDialog()
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Voorbeelden toevoegen "
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            ),
                                            _vm._l(
                                              _vm.selectedReleaseItem.media,
                                              function(media, index) {
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      key: "images-" + index,
                                                      staticClass:
                                                        "mediaContainer"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "mediaContainer__actions"
                                                        },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              attrs: {
                                                                href:
                                                                  media.original,
                                                                target: "_blank"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "action"
                                                                },
                                                                [
                                                                  _c("v-icon", [
                                                                    _vm._v(
                                                                      "remove_red_eye"
                                                                    )
                                                                  ])
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "action",
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.onClickDeleteImage(
                                                                    media
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "delete"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "delete"
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c("img", {
                                                        attrs: {
                                                          src: media.thumb
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _c(
                                          "v-layout",
                                          {
                                            staticClass: "py-3",
                                            attrs: {
                                              "justify-space-between": "",
                                              "align-center": ""
                                            }
                                          },
                                          [
                                            _c(
                                              "MiButton",
                                              {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  color: "warning",
                                                  icon: "delete",
                                                  compact: true,
                                                  outline: true
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    return _vm.onClickDelete(
                                                      _vm.selectedReleaseItem
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v(" Verwijderen ")]
                                            ),
                                            _c(
                                              "v-flex",
                                              {
                                                attrs: {
                                                  "d-flex": "",
                                                  "align-center": "",
                                                  shrink: "",
                                                  "pa-0": ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "fadedText link mr-3",
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.cancelIsEditing()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("Annuleren")]
                                                ),
                                                _c(
                                                  "MiButton",
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      color: "primary",
                                                      icon: "save",
                                                      compact: true
                                                    },
                                                    nativeOn: {
                                                      click: function($event) {
                                                        $event.stopPropagation()
                                                        return _vm.saveArticle()
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" Opslaan ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ]
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm.isDisplayingLibraryItemDialog
                    ? _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.isDisplayingLibraryItemDialog,
                            callback: function($$v) {
                              _vm.isDisplayingLibraryItemDialog = $$v
                            },
                            expression: "isDisplayingLibraryItemDialog"
                          }
                        },
                        [
                          _c("LibraryDialog", {
                            attrs: {
                              libraryType: "changelist",
                              selected: _vm.selectedLibraryItem,
                              dialogType: _vm.libraryDialogType
                            },
                            on: {
                              close: _vm.resetLibraryItem,
                              created: _vm.createdLibraryItem
                            },
                            model: {
                              value: _vm.isDisplayingLibraryItemDialog,
                              callback: function($$v) {
                                _vm.isDisplayingLibraryItemDialog = $$v
                              },
                              expression: "isDisplayingLibraryItemDialog"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isDisplayingCreateImageDialog
                    ? _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.isDisplayingCreateImageDialog,
                            callback: function($$v) {
                              _vm.isDisplayingCreateImageDialog = $$v
                            },
                            expression: "isDisplayingCreateImageDialog"
                          }
                        },
                        [
                          _c("MediaDialog", {
                            attrs: {
                              model: _vm.imageModel,
                              title: "Voorbeelden toevoegen"
                            },
                            on: {
                              created: function($event) {
                                return _vm.fetchArticleMedia(
                                  _vm.selectedReleaseItem.id || ""
                                )
                              }
                            },
                            model: {
                              value: _vm.isDisplayingCreateImageDialog,
                              callback: function($$v) {
                                _vm.isDisplayingCreateImageDialog = $$v
                              },
                              expression: "isDisplayingCreateImageDialog"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }