var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultDialog",
    {
      on: {
        close: function($event) {
          return _vm.close()
        }
      }
    },
    [
      _c("template", { slot: "header" }, [_vm._v("Nieuw artikel aanmaken")]),
      _vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [_c("LoaderCard", { attrs: { type: "spinner--center" } })],
              1
            )
          ]
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "template",
              { slot: "content" },
              [
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            label: "Naam",
                            "prepend-icon": "mdi-format-title"
                          },
                          model: {
                            value: _vm.newArticle.title,
                            callback: function($$v) {
                              _vm.$set(_vm.newArticle, "title", $$v)
                            },
                            expression: "newArticle.title"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-autocomplete", {
                          attrs: {
                            items: _vm.tags,
                            "search-input": _vm.tagSearch,
                            "item-text": "title",
                            "return-object": "",
                            multiple: "",
                            label: "Kies een tag",
                            clearable: "",
                            "prepend-icon": "mdi-tag-multiple"
                          },
                          on: {
                            keyup: _vm.debouncedSearchTags,
                            "update:searchInput": function($event) {
                              _vm.tagSearch = $event
                            },
                            "update:search-input": function($event) {
                              _vm.tagSearch = $event
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "no-data",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "no-contact-results",
                                        on: { click: _vm.createTag }
                                      },
                                      [
                                        _c("v-icon", { staticClass: "mr-1" }, [
                                          _vm._v("add")
                                        ]),
                                        _c("span", [
                                          _vm._v("Maak een nieuwe tag aan")
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            2355502291
                          ),
                          model: {
                            value: _vm.newArticle.tags,
                            callback: function($$v) {
                              _vm.$set(_vm.newArticle, "tags", $$v)
                            },
                            expression: "newArticle.tags"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("v-icon", [_vm._v("article")]),
                        _vm.newArticle.tags && _vm.newArticle.tags.length
                          ? _vm._l(_vm.newArticle.tags, function(tag, index) {
                              return _c(
                                "span",
                                {
                                  key: "tag" + index,
                                  staticClass: "tag",
                                  on: {
                                    click: function($event) {
                                      return _vm.removeTag(tag)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(tag.title) + " ")]
                              )
                            })
                          : _vm._e(),
                        !_vm.newArticle.tags || !_vm.newArticle.tags.length
                          ? [_c("span", [_vm._v("Geen tags")])]
                          : _vm._e()
                      ],
                      2
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c("QuillEditor", {
                          model: {
                            value: _vm.newArticle.content,
                            callback: function($$v) {
                              _vm.$set(_vm.newArticle, "content", $$v)
                            },
                            expression: "newArticle.content"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-flex",
                      { attrs: { xs12: "" } },
                      [
                        _c(
                          "v-layout",
                          { staticClass: "fileUpload__container" },
                          [
                            _c("MiFileUpload", {
                              ref: "fileUpload",
                              attrs: {
                                clearAfterUpload: false,
                                manualSubmit: true,
                                multiple: true
                              },
                              on: {
                                itemDropped: function($event) {
                                  return _vm.handleItemDropped($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("v-flex", { attrs: { xs12: "" } })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "template",
              { slot: "actions" },
              [
                _c(
                  "v-layout",
                  { attrs: { "justify-end": "", "align-center": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: {
                              click: function($event) {
                                return _vm.close()
                              }
                            }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.createArticle($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("actions.add")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }